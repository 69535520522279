<template>
  <div class="color">
    <div class="code-head">
      <div class="return-btn" @click="$router.go(-1)">
        回到首页
        <img :src="require('@/assets/img/return.png')" alt="" class="return-icon">
      </div>
    </div>
    <div class="title">自主填报</div>
    <div v-if="codeList.length > 0" class="code-content">
      <div v-for="item, index in codeList" :key="index" class="code-item" :class="index % 3 === 1 ? 'item2': index % 3 === 2 ? 'item3' : ''"
        @click="goInfo(item)">
        <div class="item-icon" :class="index % 3 === 1 ? 'icon2': index % 3 === 2 ? 'icon3' : ''">{{item.icon}}</div>
        <div class="item-title">{{item.name}}</div>
      </div>
    </div>
    <div v-else class="empty">
      <img :src="require('@/assets/img/code-empty.png')" alt="" class="empty-icon">
      <div class="empty-des">暂无数据</div>
    </div>
    <div v-if="codeList.length > 0" class="bottom">
      <div class="line"></div>
      <div class="bottom-des">已经到底啦～</div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      codeList: []
    }
  },
  mounted() {
    this.getQrcodeList()
  },
  methods: {
    getQrcodeList() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/qrcode/qrcodeconfig/list'),
        method: 'get',
        params: this.$http.adornParams({
          status: 0,
          orgId: this.$orgId
        })
      }).then(({data}) => {
        if(data && data.code === 0) {
          this.codeList = data.page.list.map(item => {
            let name = item.name.match(/[\u4e00-\u9fa5]/g).join("")
            console.log(name)
            return {
              ...item,
              icon: name.substr(0, 1)
            }
          })
        }
      })
    },
    goInfo(item) {
      if(item.type == 1) {
        if (this.$agentType == 1) {
          wx.miniProgram.navigateTo({
            url: item.url,
          })
        } else {
          my.navigateTo({
            url: item.url,
          })
        }
      }else if(item.type == 2) {
        if(item.name == '返程人员登记') {
          if (this.$agentType == 1) {
            wx.miniProgram.navigateTo({
              url: item.url,
            })
          } else {
            this.$router.push('/return')
          }
        }else {
          this.$router.push('/appSSO?appId=' + item.code + '&codeS=1&url='+item.url.split('/')[1])
        }
      }else if(item.type == 3) {
        this.$router.push({path:'/form',query: {code: item.code,name: item.name}})
      }else if(item.type == 4) {
        this.$router.push({path:'/questionWrite',query: {ids: item.code}})
      }
    }
  }
}
</script>
<style scoped>
.color {
  position: relative;
  padding-bottom: 120px;
  min-height: 100vh;
  background: linear-gradient(180deg, #FFFFFF 0%, #F5F5F5 100%);
}
.bottom {
  width: 100%;
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}
.line {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 60px);
  height: 1px;
  background: #EDEDED;
}
.bottom-des {
  width: 180px;
  color: #ccc;
  background: #F5F5F5;
  z-index: 999;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.code-head {
  width: 750px;
  height: 460px;
  background-image: url('~@/assets/img/code-head.png');
  background-size: 100% 100%;
  position: relative;
}
.return-btn {
  width: 268px;
  height: 112px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4581F8;
  position: absolute;
  right: 0;
  bottom: 40px;
  border-radius: 56px 0px 40px 56px;
  font-size: 32px;
  color: #fff;
}
.return-icon {
  width: 40px;
  height: 22px;
  margin-left: 12px;
}
.title {
  padding-left: 30px;
  line-height: 88px;
  font-size: 32px;
  font-weight: 600;
}
.code-content {
  padding-left: 44px;
  /* text-align: center; */
}
.code-item {
  display: inline-block;
  width: 390px;
  height: 300px;
  background-image: url('~@/assets/img/code-bg1.png');
  background-size: 100% 100%;
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  margin-left: -40px;
  position: relative;
}
.item2 {
  background-image: url('~@/assets/img/code-bg2.png');
}
.item3 {
  background-image: url('~@/assets/img/code-bg3.png');
}
.item-icon {
  position: absolute;
  top: 72px;
  left: 50%;
  transform: translateX(-50%);
  width: 64px;
  height: 64px;
  line-height: 64px;
  background-image: url('~@/assets/img/code-title1.png');
  background-size: 100% 100%;
  color: #fff;
}
.icon2 {
  background-image: url('~@/assets/img/code-title2.png');
}
.icon3 {
  background-image: url('~@/assets/img/code-title3.png');
}
.item-title {
  width: 80%;
  position: absolute;
  bottom: 96px;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.empty {
  text-align: center;
  width: 100%;
  margin-top: 80px;
}
.empty-icon {
  width: 480px;
}
.empty-des {
  margin-top: 60px;
  color: #999;
  font-size: 28px;
}
</style>